import React, {useState, useEffect} from 'react';
import {UserDetailsContext} from './UserDetailsContext';
import {useUser} from 'src/api/Auth/Auth';


// Créer le fournisseur de contexte
export const UserDetailsProvider = ({children}) => {
  const {user, isLoading, isError, mutate} = useUser();
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    if (user && !isError) {
      // Supposons que user.companyDetails soit un tableau, on prend le premier élément
      const onboardingCompleted = user.companyDetails.length > 0 ? user.companyDetails[0].onboardingCompleted : false;
      const updatedUserDetails = {...user, onboardingCompleted};
      setUserDetails(updatedUserDetails);
    }
  }, [user, isError]);

  const value = {
    userDetails,
    isLoading,
    isError,
    mutate,
  };

  return (
    <UserDetailsContext.Provider value={value}>
      {children}
    </UserDetailsContext.Provider>
  );
};
