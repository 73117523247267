import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import ReactGA from 'react-ga4';
import 'src/locales/i18n';
import 'simplebar-react/dist/simplebar.min.css';
import 'react-quill/dist/quill.snow.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
/* import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css'; */
/* import 'mapbox-gl/dist/mapbox-gl.css'; */
/* import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; */
// routes
import Router from 'src/routes/pages';
// theme
import ThemeProvider from 'src/theme';
// locales
import {LocalizationProvider} from 'src/locales';
// hooks
import {useScrollToTop} from 'src/hooks/useScrollToTop';
// components
import ProgressBar from 'src/components/progress-bar';
import {MotionLazy} from 'src/components/animate/motion-lazy';
import {SnackbarProvider} from 'src/components/snackbar';
import {SettingsProvider, SettingsDrawer} from 'src/components/settings';

/**
 * Composant principal de l'application. C'est le point d'entrée de l'application React.
 * Ce composant initialise les providers pour les thèmes, les settings, et l'authentification.
 * Il englobe également les composants principaux tels que le router, la barre de progression, le tiroir de settings, etc.
 * Le hook `useScrollToTop` est appelé pour gérer le défilement automatique vers le haut lors de la navigation entre les pages.
 *
 * @return {JSX.Element}
 */
export default function App() {
  const location = useLocation();
  useScrollToTop();

  useEffect(() => {
    try {
      ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    } catch (error) {
      console.error('Failed to initialize GA4:', error);
    }
  }, []);

  useEffect(() => {
    ReactGA.send({hitType: 'pageview', page: location.pathname + location.search});
  }, [location.pathname, location.search]);

  return (
    <LocalizationProvider>
      <SettingsProvider
        defaultSettings={{
          themeMode: 'light', // 'light' | 'dark'
          themeDirection: 'ltr', //  'rtl' | 'ltr'
          themeContrast: 'default', // 'default' | 'bold'
          themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
          themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          themeStretch: false,
        }}
      >

        <ThemeProvider>
          <MotionLazy>
            <SnackbarProvider>
              <SettingsDrawer />
              <ProgressBar />
              <Router />
            </SnackbarProvider>
          </MotionLazy>
        </ThemeProvider>
      </SettingsProvider>
    </LocalizationProvider>
  );
}
