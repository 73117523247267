import {lazy} from 'react';
import {Outlet} from 'react-router-dom';
// layouts
import CompactLayout from 'src/layouts/compact';
import LargeLayout from 'src/layouts/large';

// ----------------------------------------------------------------------

const TermsOfServicePage = lazy(() => import('src/pages/legal/view/TermsOfServicePage'));
const PrivacyPolicyPage = lazy(() => import('src/pages/legal/view/PrivacyPolicyPage'));
const Page403 = lazy(() => import('src/pages/error/view/403Page'));
const Page404 = lazy(() => import('src/pages/error/view/404Page'));
const StripeSuccess = lazy(() => import('src/pages/app/onboarding/view/StripeSuccessPage'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <CompactLayout>
        <Outlet />
      </CompactLayout>
    ),
    children: [
      {path: '403', element: <Page403 />},
      {path: '404', element: <Page404 />},
      {path: 'stripe-success', element: <StripeSuccess />},
    ],
  },
  {
    element: (
      <LargeLayout>
        <Outlet />
      </LargeLayout>
    ),
    children: [
      {path: 'terms-of-service', element: <TermsOfServicePage />},
      {path: 'privacy-policy', element: <PrivacyPolicyPage />},
    ],
  },
];
