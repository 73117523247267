import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Typography, Tooltip, IconButton, Stack} from '@mui/material';
import {useUserDetailsContext} from 'src/hooks';
import {useCopyToClipboard} from 'src/hooks/useCopyToClipboard';
import {useSnackbar} from 'src/components/snackbar';
import {FRONTEND_URL} from 'src/ConfigGlobal';
import {paths} from 'src/routes/paths';
import Iconify from 'src/components/iconify';

/**
 * Composant pour afficher le lien vers la page de vente de l'établissement
 * @return {JSX.Element}
 */
export default function SalesPageLink() {
  const {userDetails} = useUserDetailsContext();
  const {copy} = useCopyToClipboard();
  const {enqueueSnackbar} = useSnackbar();

  const publicSlug = userDetails?.companyDetails?.[0]?.slug;
  const publicUrl = `${FRONTEND_URL}${paths.company.root}/${publicSlug}`;

  const onCopy = useCallback(() => {
    copy(publicUrl);
    enqueueSnackbar('Lien copié !', {variant: 'success'});
  }, [copy, enqueueSnackbar, publicUrl]);

  if (!publicSlug) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={{mr: 2}}>
      <Iconify
        icon="ph:link"
        width={20}
        sx={{mr: 1, color: 'text.secondary'}}
      />
      <Typography variant="subtitle2" noWrap sx={{color: 'text.primary'}}>
          Ma page de vente
      </Typography>
      <Tooltip title="Copier le lien">
        <IconButton
          onClick={onCopy}
          size="small"
          sx={{color: 'primary.main'}}
        >
          <Iconify
            icon="eva:copy-fill"
            width={22}
          />
        </IconButton>
      </Tooltip>
      <Tooltip title="Ouvrir dans un nouvel onglet">
        <IconButton
          component="a"
          href={publicUrl}
          target="_blank"
          size="small"
          sx={{color: 'primary.main'}}
        >
          <Iconify
            icon="eva:external-link-fill"
            width={22}
          />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

SalesPageLink.propTypes = {
  publicSlug: PropTypes.string,
  publicUrl: PropTypes.string,
};
