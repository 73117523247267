import {lazy} from 'react';

// ----------------------------------------------------------------------

/* const HomePage = lazy(() => import('src/pages/public/home/view/HomePage')); */
const GiftVoucherPage = lazy(() => import('src/pages/public/gift-voucher/view/GiftVouchersListPage'));
const PurchasePage = lazy(() => import('src/pages/public/gift-voucher/view/PurchasePage'));
const TermsPage = lazy(() => import('src/pages/public/terms/view/TermsPage'));
const ContactPage = lazy(() => import('src/pages/public/contact/view/ContactPage'));

// ----------------------------------------------------------------------

export const publicRoutes = [
  {
    path: '/explore',
    children: [
      {path: ':slug', element: <GiftVoucherPage />}, // Page d'accueil
      /*       {path: ':slug/gift-vouchers', element: <GiftVoucherPage />}, // Liste des bons cadeaux */
      {path: ':slug/buy/:voucherId', element: <PurchasePage />}, // Page d'achat de bon cadeau
      {path: ':slug/terms', element: <TermsPage />}, // Page de CGV
      {path: ':slug/contact', element: <ContactPage />}, // Page de contact

    ],
  },
];
