// @mui
import {useTheme} from '@mui/material/styles';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// theme
import {bgBlur} from 'src/theme/css';
// hooks
import {useOffSetTop} from 'src/hooks/useOffSetTop';
// components
import Logo from 'src/components/logo';
//
import {HEADER} from '../config-layout';
import HeaderShadow from './header-shadow';
import {Typography} from '@mui/material';

// ----------------------------------------------------------------------
/**
 * `HeaderSimple` component provides a simple header with a logo,
 * settings button, and a help link. The height and background of
 * the header adjust based on the scroll position.
 *
 * @return {JSX.Element} The rendered component.
 */
export default function HeaderSimple() {
  /**
   * MUI theme object.
   *
   * @type {Object}
   */
  const theme = useTheme();
  /**
   * Whether the viewport has been scrolled past the desktop header height.
   *
   * @type {boolean}
   */
  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  return (
    <AppBar>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Logo />

        <Stack direction="row" alignItems="center" spacing={1}>

          <Link href="mailto:hello@bookizy.app" color="inherit">
            <Typography variant="subtitle2">Besoin d&apos;aide ?</Typography>
          </Link>
        </Stack>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
